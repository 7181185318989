import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "gatsby";

const ContactButton = styled.a`
  background: transparent;
  border: none;
  cursor: pointer;
`;

const FooterLink = styled(Link)`
  color: #FFF !important;
  background: transparent;
  border: none;
  cursor: pointer;
  font-weight: 700 !important;
`;

const Footer = () => {
  const onContactUs = () => {
    if (window && window.INSTANT_DEMO) {
      window.INSTANT_DEMO.initiate_flow('e1d47f39-1e3f-45b4-8948-b2eb2ec14dfe', { start_scheduling: true })
    }

    if (window !== "undefined" && window.gtag) {
      window.gtag("event", "conversion", { send_to: 'AW-411216111/QZPVCKPc9_0BEO_RisQB' })
    }
  }

  return (
    <>
      <div className="footer-section bg-blackish-blue py-9 dark-mode-texts">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg="4" md="4">
              <div className="footer-logo text-center text-md-left mb-7 mb-md-0 gr-text-color">
                © 2022 Instant Demo Ltd. All Right Reserved.
              </div>
            </Col>
            <Col lg="7" md="7" className="offset-lg-1">
              <ul className="footer-links gr-text-11 gr-text-color py-lg-7 list-unstyled mb-0 d-xs-flex align-items-center justify-content-around justify-content-md-end flex-wrap">
                <li className="ml-md-12 text-center">
                  <ContactButton
                    onClick={onContactUs}
                    className="gr-text-color font-weight-bold"
                  >
                    Contact Us
                  </ContactButton>{' '}|{' '}
                  <FooterLink to="/terms">Terms & Conditions</FooterLink>{' '}|{' '}
                  <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;
