import React, { useState, useContext } from "react";
import styled from "styled-components";
import { ListGroup, Collapse } from "react-bootstrap";
import { FaAngleRight, FaAngleDown } from "react-icons/fa";
import { Link } from "gatsby";
import GlobalContext from "../../context/GlobalContext";

const NestedMenuContainer = styled.div`
  a,
  .label {
    color: ${({ dark, theme }) =>
      dark ? theme.colors.light : theme.colors.heading}!important;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.3s ease-out;
    &:hover,
    &:active {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: none;
    }
  }

  .list-group-item {
    & + .collapse:not(.show) {
      .list-group-item {
        border: none !important;
        border-width: 0 !important;
      }
    }
    & + .collapse.show {
      .list-group-item {
        &:first-child {
          border-top: none !important;
        }
      }
    }
  }
  .collapse + .list-group-item {
    border-top-width: 0;
  }
  /* .list-group-flush:last-child .list-group-item:last-child {
    border-bottom-width: 1px;
  } */
`;

const renderLink = ({name, label, gContext, isExternal, isJsFunction, executeJs}) => {
  if (isExternal) {
    return (
      <a
        href={`${name}`}
        onClick={() => {
          if (gContext.visibleOffCanvas) {
            gContext.toggleOffCanvas();
          }
        }}
      >
      {label}
      </a>
    )
  }

  if (isJsFunction) {
    return (
      <a
        style={{cursor: 'pointer'}}
        onClick={() => {
          executeJs()
          if (gContext.visibleOffCanvas) {
            gContext.toggleOffCanvas();
          }
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {label}
      </a>
    )
  }

  return (
    <Link
      to={`/${name}`}
      onClick={() => {
        if (gContext.visibleOffCanvas) {
          gContext.toggleOffCanvas();
        }
      }}
    >
      {label}
    </Link>
  )
}

const MenuItem = ({
  label,
  isExternal = false,
  showOnlyInCollapsed = false,
  isJsFunction = false,
  executeJs = () => {},
  name,
  items,
  depthStep = 20,
  depth = 0,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const hasSubItems = Array.isArray(items);
  const gContext = useContext(GlobalContext);

  if (hasSubItems) {
    return (
      <>
        <ListGroup.Item
          {...rest}
          css={`
            padding-left: ${depth * depthStep}px !important;
            cursor: pointer;
          `}
          onClick={() => setOpen(!open)}
          className="d-flex align-items-center justify-content-between"
        >
          <span className="label">{label}</span>
          <span>{open ? <FaAngleDown /> : <FaAngleRight />}</span>
        </ListGroup.Item>
        <Collapse in={open}>
          <ListGroup>
            {items.map((subItem) => (
              <MenuItem
                key={subItem.name}
                depth={depth + 1}
                depthStep={depthStep}
                {...subItem}
              />
            ))}
          </ListGroup>
        </Collapse>
      </>
    )
  }

  return (
    <ListGroup.Item
      {...rest}
      css={`
        padding-left: ${depth * depthStep}px !important;
      `}
    >
      {renderLink({name, label, gContext, isExternal, isJsFunction, executeJs})}
    </ListGroup.Item>
  );
};

const NestedMenu = ({ menuItems }) => {
  return (
    <NestedMenuContainer>
      <ListGroup variant="flush">
        {menuItems.map((menuItem, index) => (
          <MenuItem
            key={`${menuItem.name}${index}`}
            depthStep={20}
            depth={0}
            {...menuItem}
          />
        ))}
      </ListGroup>
    </NestedMenuContainer>
  );
};

export default NestedMenu;
