import React from "react";
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from 'gatsby'

import imgFavicon from "../../assets/image/purple-favicon.png";
import appleTouch from "../../assets/image/apple-touch.png";

const SEO = ({ title, sharingTitle, description, relativePath, imageUrl }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          defaultTitle: title
          defaultDescription: description
          siteUrl: url
          defaultImage: image
        }
      }
    }
  `)

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage
  } = data.site.siteMetadata

  const imageToUse = (imageUrl || defaultImage)
  const imagePath = imageToUse.startsWith('http') ? imageToUse : `${process.env.GATSBY_SITE_URL}${imageToUse}`

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
    >
      <title>{title || defaultTitle}</title>
      <link rel="icon" type="image/png" href={imgFavicon} />
      <link rel="apple-touch-icon" href={appleTouch} />
      <meta name="description" content={description || defaultDescription} />
      <meta name="image" content={imagePath} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${siteUrl}/${relativePath}`} />
      <meta property="og:title" content={sharingTitle || defaultTitle} />
      <meta property="og:description" content={description || defaultDescription} />
      <meta property="og:image" content={imagePath} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={sharingTitle || defaultTitle} />
      <meta name="twitter:description" content={description || defaultDescription} />
      <meta name="twitter:image" content={imagePath} />
    </Helmet>
  )
};

SEO.propTypes = {
  title: PropTypes.string,
  sharingTitle: PropTypes.string,
  description: PropTypes.string,
  relativePath: PropTypes.string,
  imageUrl: PropTypes.string
}

export default SEO;