import { navigate } from "gatsby"

export const menuItems = [
  {
    name: "solutions",
    label: 'Solutions',
    items: [
      { name: "sales", label: "Sales" },
      { name: "pre-sales", label: "Pre-Sales" },
      { name: "marketing", label: "Marketing" }
    ]
  },
  {
    name: "Schedule Meeting",
    label: "Schedule Meeting",
    isJsFunction: true,
    executeJs: () => {
      if (window && window.INSTANT_DEMO) {
        window.INSTANT_DEMO.initiate_flow('e1d47f39-1e3f-45b4-8948-b2eb2ec14dfe', { start_scheduling: true })
      }

      if (window !== "undefined" && window.gtag) {
        window.gtag("event", "conversion", { send_to: 'AW-411216111/QZPVCKPc9_0BEO_RisQB' })
      }
    }
  },
  {
    name: "pricing",
    label: "Pricing",
    isJsFunction: true,
    executeJs: () => {
      navigate('/#pricing', { replace: true })
    }
  },
  {
    name: "blog",
    label: "Blog"
  },
  {
    name: process.env.GATSBY_INSTANT_DEMO_APP_URL,
    isExternal: true,
    showOnlyInCollapsed: true,
    label: "Login"
  },
];
