import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { device } from "../../utils";

const StyledContainer = styled.div`
  display: block;
  width: 200px;
  @media ${device.lg} {
    width: 260px;
  }
`

const Logo = ({ white, height, className = "", ...rest }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "black-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 260) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Link to="/" className={`d-block ${className}`} {...rest}>
      <StyledContainer>
        <Img fluid={data.logo.childImageSharp.fluid} alt="logo" />
      </StyledContainer>
    </Link>
  );
};

export default Logo;
